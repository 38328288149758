import React from 'react'
import sofa from "../images/sofa.png"
import envelope from "../images/envelope-outline.svg"

import "../bootstrapCss/style.css";
import IconComponent from './IconCoponents';
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <>
  {/* Start Footer Section */}
  <footer className="footer-section">
    <div className="container">
      
      <div className="row">
        
        <div className="col-lg-12">
          <div className="row links-wrap">
            <div className="col-6 col-sm-6 col-md-4">
              <ul className="list-unstyled">
              <li>
                  <a href="#">Home</a>
                </li>
                <li>
                  <a href="#">About us</a>
                </li>
                <li>
                  <a href="#">Services</a>
                </li>
                
                <li>
                  <a href="#">Contact us</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 ">
          <div className="mb-4 footer-logo-wrap text-center">
            <a href="#" className="footer-logo">
              Zgomo
            </a>
          </div>
          <p className="mb-4 text-center">
          Whether you need a new website or want to upgrade your current one, let’s connect!
          </p>
          <p className='text-center ft-hover'>
            <Link to="mailto:services@zgomo.com"><b>services@zgomo.com</b></Link>
          </p>
          <ul className="list-unstyled custom-social">
            <li>
              <a href="#">
                {/* <span className="fa fa-brands fa-facebook-f" /> */}
                <IconComponent iconName="whatsapp" />
    
              </a>
            </li>
            <li>
              <a href="#">
              <IconComponent iconName="google" />
    
              </a>
            </li>
            <li>
              <a href="#">
              <IconComponent iconName="twitter" />
              </a>
            </li>
            <li>
              <a href="#">
              <IconComponent iconName="facebook" />
              </a>
            </li>
          </ul>
        </div>
            <div className="col-6 col-sm-6 col-md-4">
              <ul className="list-unstyled">
                <li>
                  <a href="#">Website Design</a>
                </li>
                <li>
                  <a href="#">Social Media Optimisation</a>
                </li>
                <li>
                  <a href="#">Search Engine Optimization</a>
                </li>
              </ul>
            </div>
            
          </div>
        </div>
      </div>

      <div className="border-top copyright">
        <div className="row">
          <div className="col-lg-12">
            <p className="mb-2 text-center">
            © copyright 2024 . All Rights Reserved. Site Created & Maintained By Zgomo
            </p>
          </div>
          
        </div>
      </div>
    </div>
  </footer>
  
  {/* End Footer Section */}
</>

  )
}

export default Footer