import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../Pages/Home'
import Service from '../Pages/Service'
import About from '../Pages/About'
import Smo from '../Pages/Smo'
import Seo from '../Pages/Seo'
import Webdesign from '../Pages/Webdesign'
import Contact from '../Pages/Contact'

const AllRoutes = () => {
  return (
    <div>
      <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/about" element={<About/>} />
      
        <Route path="/services"  element={<Service/>}/>
        <Route path="/smo"  element={<Smo />}/>
        <Route path="/seo"  element={<Seo/>}/>
        <Route path="/webdesign"  element={<Webdesign/>}/>
        <Route path="/contact"  element={<Contact/>}/>
        
      </Routes>
    </div>
  )
}

export default AllRoutes
