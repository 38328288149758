// IconComponent.jsx
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faGoogle, faTwitter,faFacebook  } from '@fortawesome/free-brands-svg-icons';

const IconComponent = ({ iconName }) => {
  const icons = {
    whatsapp: faWhatsapp,
    google: faGoogle,
    twitter: faTwitter,
    facebook: faFacebook
  };

  return <FontAwesomeIcon icon={icons[iconName]} />;
};

export default IconComponent;
