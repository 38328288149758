import React from 'react'
import grid1 from "../../images/person2.jpg";
import grid2 from "../../images/responsive-website-animations-copy.gif";
import grid3 from "../../images/person-code.jpg";
import "../../bootstrapCss/style.css";

import style from "../About/DotComponents.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const WelcomeSection3 = () => {
  return (
   <>
  {/* Start We Help Section */}
  <div className="we-help-section">
    <div className="container">
      <div className="row justify-content-between align-items-center">
        <div className="col-lg-7 mb-5 mb-lg-0">
          <div className="imgs-grid">
            <div className="grid grid-1">
              <img src={grid1} alt="Untree.co" />
            </div>
            <div className="grid grid-2">
              <img src={grid2} alt="Untree.co" />
            </div>
            <div className="grid grid-3">
              <img src={grid3} alt="Untree.co" />
            </div>
          </div>
        </div>
        <div className="col-lg-5 ps-lg-5">
          <h2 className="section-title mb-4">
            We Help You Make <span className="span-clr">Awesome Design</span> And <span className='span-clr'>Custom Design</span>
          </h2>
          <p>
          At Zgomo, we specialize in creating exceptional and custom web designs tailored to your unique business needs. Our team combines creativity with technical expertise to deliver stunning, user-friendly, and responsive websites that enhance your brand’s online presence.
          </p>
          <ul className={`row my-5 ${style.noListStyle}`}>
                <li className={`col-12 ${style.listItem}`}>
                  <FontAwesomeIcon icon={faCheckCircle} className={style.icon} />
                  Responsive Design: Optimized for all devices
                </li>
                <li className={`col-12 ${style.listItem}`}>
                  <FontAwesomeIcon icon={faCheckCircle} className={style.icon} />
                  Tailored Solutions: Custom designs that reflect your brand.
                </li>
                <li className={`col-12 ${style.listItem}`}>
                  <FontAwesomeIcon icon={faCheckCircle} className={style.icon} />
                  Creative Excellence: Innovative and engaging design concepts.
                </li>
              </ul>
          <p>
            <a herf="#" className=" my-btn">
              Explore
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
  {/* End We Help Section */}
</>

  )
}

export default WelcomeSection3