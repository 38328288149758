import {useEffect} from 'react'
import { Outlet } from 'react-router-dom';

// import Navbar from '../Navbar';
// import Footer from '../Footer';

import Slider1 from "../Components/HomePage//Slider1";
import WelcomeSection from "../Components/HomePage/WelcomeSection";
import WelcomeSection2 from "../Components/HomePage/WelcomeSection2";
import WelcomeSection3 from "../Components/HomePage/WelcomeSection3";
// import WelcomSection4 from "./WelcomSection4";
import Testimonial from "../Components/HomePage/Testimonial";
import Blog from "../Components/HomePage//Blog";

const Home = () => {
    
  useEffect(() => {
    const timeout = setTimeout(() => {
      window.scrollTo(0, 0);
    }, ); // Adjust the delay as needed

    return () => clearTimeout(timeout); // Clear timeout on component unmount
  }, []); 
  return (
    <>
    <Slider1 /> 
      <WelcomeSection />
      <Blog />

      <WelcomeSection2 />

      <WelcomeSection3 />
      
      <Testimonial />
      <Outlet /> 
    </>
  )
}

export default Home
