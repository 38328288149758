import React from 'react'
import Welcome1 from '../Components/Services/Welcome1'

const Service = () => {
  return (
    <>
    <Welcome1/>
    </>
  )
}

export default Service
