
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";


import Navbar from "./Components/Navbar";

import Footer from "./Components/Footer";

import AllRoutes from "./Routes/AllRoutes";





function App() {
  return (
    <>
     <Navbar/>
    <AllRoutes/>
    <Footer/>
    </>
   

  );
}

export default App;
