import {useEffect} from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Accordion from 'react-bootstrap/Accordion';

import webdesign from "../images/service1.jpg";
import SectionThree from "../Components/About/SectionThree";

const Webdesign = () => {
    useEffect(() => {
        const timeout = setTimeout(() => {
          window.scrollTo(0, 0);
        }, ); // Adjust the delay as needed
    
        return () => clearTimeout(timeout); // Clear timeout on component unmount
      }, []); 
    
      return (
        <>
          <div className="banner-layout">
            <div className="container">
              {/* <img className='img-fluid' src={bannerlayout} /> */}
              <div className="text-center">
                <h1 className="text-white">website Design</h1>
                <Breadcrumb>
                  <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href="#">Sevices</Breadcrumb.Item>
                  <Breadcrumb.Item active>
                  website Design
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
          </div>
    
          <div className="ser-lay section-entry ">
            <div className="container">
              <div className="row align-c">
                <div className="col-md-6">
                  <div>
                    <img className="img-fluid" src={webdesign} />
                  </div>
                </div>
                <div className="col-lg-6">
                  <h2 className="section-title mb-4">
                    Website Design{" "}
                    <span className="span-clr">(Social Media Optimization)</span>
                  </h2>
                  <p>
                  A compelling web design is the cornerstone of a successful online presence. At Zgomo, we understand that your website is often the first impression your audience has of your business. Our web design services are focused on creating visually stunning, user-friendly websites that effectively communicate your brand's message and drive engagement.
                  </p>
                  <p>
                  Our team of expert designers takes a user-centric approach to web design, ensuring that every element from layout to navigation is intuitively designed for the best user experience. We combine creativity with the latest design trends and technologies to build websites that are not only beautiful but also responsive and functional across all devices.
                  </p>
                </div>
                <p>
                Whether you need a new website or a redesign of an existing one, we tailor our services to meet your specific needs and objectives. From initial concept to final launch, we work closely with you to ensure your vision is brought to life. Let us help you create a web design that not only looks great but also helps achieve your business goals.
                </p>
              </div>
            </div>
          </div>
    
    
          {/* <div className="ser-wel section-entry">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 ser-col">
                    <h2 className="section-title text-center mb-4">
                    Our{" "}
                    <span className="span-clr">Process</span>
                  </h2>
                    </div>
                    <div className="col-md-4 ser-cl-col">
                        <div className="ser-wrap-inside">
                            <h4>Step 1</h4>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure aliquam velit sit animi eligendi, commodi nostrum? Ipsum, ex totam! Cum iure praesentium accusamus accusantium dolores molestias est quo quasi inventore?</p>
                        </div>
                    </div>
                    <div className="col-md-4 ser-cl-col">
                        <div className="ser-wrap-inside">
                            <h4>Step 2</h4>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure aliquam velit sit animi eligendi, commodi nostrum? Ipsum, ex totam! Cum iure praesentium accusamus accusantium dolores molestias est quo quasi inventore?</p>
                        </div>
                    </div>
                    <div className="col-md-4 ser-cl-col">
                        <div className="ser-wrap-inside">
                            <h4>Step 3</h4>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure aliquam velit sit animi eligendi, commodi nostrum? Ipsum, ex totam! Cum iure praesentium accusamus accusantium dolores molestias est quo quasi inventore?</p>
                        </div>
                    </div>
                </div>
            </div>
          </div> */}
    
    
          {/* <div className="ser-fqa section-entry">
            <div className="container">
                <div className="row ser-fqa align-c">
                    <div className="col-md-6">
                        <div className="ser-fqa-l">
                        <h2 className="section-title">
                    Our <span className="span-clr">FQAS</span> </h2>
                            <h4>Have Questions? We Have Answers!</h4>
                            <p>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum quis aliquid voluptates quasi reprehenderit natus distinctio possimus. Voluptatem similique aperiam commodi numquam sapiente distinctio est!
                            </p>
                            <p>
                                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quasi alias deleniti inventore tenetur quis voluptatibus maiores. Unde quia iusto voluptates odio dignissimos sint mollitia, maiores quam reprehenderit numquam quos amet?
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6 ser-fqa-col">
                        <div className="ser-fqa-wrap">
                        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>What is SEO and why do I need it for my website?</Accordion.Header>
            <Accordion.Body>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
              minim veniam, quis nostrud exercitation.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>How long does it take to see results from SEO?</Accordion.Header>
            <Accordion.Body>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
              minim veniam, quis nostrud exercitation ullamco.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>What kind of SEO strategies does Quixta offer?</Accordion.Header>
            <Accordion.Body>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
              minim veniam, quis nostrud exercitation ullamco.
            </Accordion.Body>
          </Accordion.Item>
          
        </Accordion>
                        </div>
                    </div>
                </div>
            </div>
          </div> */}
    
        <SectionThree />
    
        </>
      );
}

export default Webdesign
