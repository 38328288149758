import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import "../bootstrapCss/style.css";

const Navbar = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isMobileDropdownOpen, setMobileDropdownOpen] = useState(false);
  const [isSticky, setSticky] = useState(false);

  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleMobileDropdownToggle = (e) => {
    e.stopPropagation();
    setMobileDropdownOpen(!isMobileDropdownOpen);
  };

  const handleScroll = () => {
    if (window.scrollY > 200) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className={`navbar ${isSticky ? 'sticky' : ''}`}>
      <div className="container">
        <div className="nav-center">
          <div className="navbar-brand"><a href='/'><span className="logo">Zgomo</span></a></div>
          <ul className="navbar-links">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="about">About Us</a>
            </li>
            <li
              className="dropdown"
              onMouseEnter={handleDropdownToggle}
              onMouseLeave={handleDropdownToggle}
            >
              <a href="services">Services</a>
              <ul className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`}>
                <li><a href="/smo">Social Media Optimisation </a></li>
                <li><a href="/seo">Search Engine Optimization</a></li>
                <li><a href="/webdesign">Website Design & Development</a></li>
              </ul>
            </li>
            <li>
              <a href="/contact">Contact Us</a>
            </li>
          </ul>
          <div>
            <Link to="tel:919354414923" className="my-btn">+919354414923</Link>
          </div>
        </div>
        <div className="navbar-mobile-menu">
          <button onClick={handleMobileMenuToggle}>Menu</button>
          <ul className={`mobile-dropdown-menu ${isMobileMenuOpen ? 'show' : ''}`}>
            <li><a href="#home">Home</a></li>
            <li className="mobile-dropdown">
              <div className="mobile-dropdown-header">
                <a href="#services">Services</a>
                <button onClick={handleMobileDropdownToggle}>▼</button>
              </div>
              <ul className={`mobile-submenu ${isMobileDropdownOpen ? 'show' : ''}`}>
                <li><a href="#service1">Service 1</a></li>
                <li><a href="#service2">Service 2</a></li>
                <li><a href="#service3">Service 3</a></li>
              </ul>
            </li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
