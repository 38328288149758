import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPercent } from '@fortawesome/free-solid-svg-icons';
import styles from './CounterStats.module.css';
import "../../bootstrapCss/style.css";

const CounterStats = () => {
  const [stats, setStats] = useState([
    { type: 'Years In Business', count: 7 },
    { type: 'Clients', count: 200 },
    { type: 'Referral Clients', count: 80 }
  ]);

  useEffect(() => {
    const countStats = document.querySelectorAll(`.${styles.counting}`);

    countStats.forEach(stat => {
      const target = +stat.getAttribute('data-count');
      let count = 0;
      const increment = target / 100;

      const updateCount = () => {
        const value = Math.ceil(count);
        stat.innerHTML = value;
        count += increment;
        if (count < target) {
          setTimeout(updateCount, 30);
        } else {
          stat.innerHTML = target;
        }
      };

      updateCount();
    });
  }, []);

  return (
    <div className='counter-sec'>
      <section id="counter-stats" className="wow fadeInRight" data-wow-duration="1.4s" >
      <div className="container">
        <div className="row">
          {stats.map((stat, index) => (
            <div key={index} className={`col-lg-4 ${styles.stats}`}>
              <div className={styles.statsItem}>
                <div className={`${styles.counting}`} data-count={stat.count}>0</div>
                {index === stats.length - 1 ? <FontAwesomeIcon icon={faPercent} className={styles.icon} /> : <FontAwesomeIcon icon={faPlus} className={styles.icon} />}
                <h3>{stat.type}</h3>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
    </div>
  );
};

export default CounterStats;
