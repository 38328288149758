import React from "react";
import bannerlayout from "../../images/service1.jpg";
import bannerlayout2 from "../../images/service2.jpg";
import bannerlayout3 from "../../images/service3.jpg";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";

const Welcome1 = () => {
  
  return (
    <>
      <div className="banner-layout">
        <div className="container">
          {/* <img className='img-fluid' src={bannerlayout} /> */}
          <div className="text-center">
            <h1 className="text-white">Our Services</h1>
            <Breadcrumb>
              <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Services</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>

      <div className="ser-wel section-entry">
        <div className="container">
          <div className="row ser-wel-row align-c">
            <div className="col-md-6">
              <div className="ser-wrap">
                <img src={bannerlayout} className="img-fluid" alt="ser1" />
              </div>
            </div>
            <div className="col-lg-6">
          <h2 className="section-title mb-4">
            Website <span className="span-clr">Design</span> 
          </h2>
          <p>
          A compelling web design is the cornerstone of a successful online presence. At Zgomo, we understand that your website is often the first impression your audience has of your business. Our web design services are focused on creating visually stunning, user-friendly websites that effectively communicate your brand's message and drive engagement.
          </p>
         <p>
         Our team of expert designers takes a user-centric approach to web design, ensuring that every element from layout to navigation is intuitively designed for the best user experience. 
         </p>
          <p className="mt-4">
            <Link to="/webdesign" className="my-btn">
              Know More
            </Link>
          </p>
        </div>
          </div>
          <div className="row ser-wel-row align-c mt-4 mb-4">
            <div className="col-md-6">
              <div className="ser-wrap">
                <img src={bannerlayout2} className="img-fluid" alt="ser1" />
              </div>
            </div>
            <div className="col-lg-6">
          <h2 className="section-title mb-4">
          SEO<span className="span-clr"> (Search Engine Optimization)</span> 
          </h2>
          <p>
          In the competitive digital landscape, standing out in search engine results is crucial for attracting organic traffic and growing your business. At Zgomo, we specialize in comprehensive Search Engine Optimization (SEO) services designed to enhance your website's visibility and drive targeted traffic.


          </p>
          <p>
          Our SEO strategy begins with a thorough analysis of your website and industry to identify opportunities for improvement. We conduct extensive keyword research to ensure your content aligns with what your audience is searching for.
          </p>
          <p className="mt-4">
          <Link to="/seo" className="my-btn">
              Know More
            </Link>
          </p>
        </div>
          </div>
          <div className="row ser-wel-row align-c">
            <div className="col-md-6">
              <div className="ser-wrap">
                <img src={bannerlayout3} className="img-fluid" alt="ser1" />
              </div>
            </div>
            <div className="col-lg-6">
          <h2 className="section-title mb-4">
          SMO <span className="span-clr">(Social Media Optimization)</span> 
          </h2>
          <p>
          In today's digital age, having a strong social media presence is essential for any business aiming to connect with its audience and build brand awareness. Social Media Optimization (SMO) involves strategically using social media platforms to enhance your online presence, drive traffic, and engage with your audience.
          </p>
          <p>At Zgomo, we specialize in crafting effective SMO strategies tailored to your business needs. </p>
          <p className="mt-4">
          <Link to="/smo" className="my-btn">
              Know More
            </Link>
          </p>
        </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Welcome1;
