import React from 'react'
import post1 from "../../images/ser3.jpg";
import post2 from "../../images/ser1.jpg";
import post3 from "../../images/ser2.jpg";

import "../../bootstrapCss/style.css";
import { Link } from "react-router-dom";

const Blog = () => {
  return (
    <>
  {/* Start Blog Section */}
  <div className="blog-section hm-ser section-entry">
    <div className="container">
      <div className="row mb-4">
        <div className="col-md-12">
          <h2 className="section-title text-center">Our <span className='span-clr'>Services</span></h2>
        </div>
        {/* <div className="col-md-6 text-start text-md-end">
          <Link to="#" className="more">
            View All Posts
          </Link>
        </div> */}
      </div>
      <div className="row">
        <div className="col-12 col-sm-6 col-md-4 mb-4 mb-md-0">
          <div className="post-entry">
            <Link to="/smo" className="post-thumbnail">
              <img src={post1} alt="Image" className="img-fluid" />
              <div className='mt-3'>
                <h3 className="ser-title">SMO</h3>
                <p>In today's digital age, having a strong social media presence is essential for any business aiming to connect with its audience and build brand awareness. Social Media Optimization (SMO) involves strategically using social media platforms to enhance your online presence, drive traffic, and engage with your audience.



</p>
              </div>
            </Link>
            
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 mb-4 mb-md-0">
          <div className="post-entry">
            <Link to="/webdesign" className="post-thumbnail">
              <img src={post2} alt="Image" className="img-fluid" />
              <div className='mt-3'>
                <h3 className="ser-title">Web Design</h3>
                <p>A compelling web design is the cornerstone of a successful online presence. At [Your Company Name], we understand that your website is often the first impression your audience has of your business. Our web design services are focused on creating visually stunning, user-friendly websites that effectively communicate your brand's message and drive engagement.

</p>
              </div>
            </Link>
            
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 mb-4 mb-md-0">
          <div className="post-entry">
            <Link to="/seo" className="post-thumbnail ">
              <img src={post3} alt="Image" className="img-fluid" />
              <div className='mt-3'>
                <h3 className="ser-title">SEO</h3>
                <p>In the competitive digital landscape, standing out in search engine results is crucial for attracting organic traffic and growing your business. At [Your Company Name], we specialize in comprehensive Search Engine Optimization (SEO) services designed to enhance your website's visibility and drive targeted traffic.
</p>
              </div>
            </Link>
          
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* End Blog Section */}
</>

  )
}

export default Blog