import React from 'react'
import product1 from "../../images/idea.png";
import crossSvg from "../../images/vector.png";
import product2 from "../../images/magic-wand.png";
import product3 from "../../images/product-3.png";
import "../../bootstrapCss/style.css";
const WelcomeSection = () => {
  return (
      <>


<div className="services section section-entry" id="services">
  <div className="container">
    <div className="row">
      <div className="offset-md-2 col-md-8">
        <div className="hm-ser-sm-title text-center mb-4">
            <h6>Crafting Websites That Drive Results</h6>
            <h3>Expert  <span className='span-clr'> Website Development</span></h3>
            <p> At Zgomo, we specialize in delivering top-tier website development services that transform your ideas into reality. Our expert team of developers uses the latest technologies and best practices to build websites that are fast, secure, and scalable. Whether you need a simple informational site or a complex e-commerce platform, we ensure that your website is tailored to your unique business requirements. </p>
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="service-item">
          <div className="icon">
            <img src={product1} alt="online degrees" />
          </div>
          <div className="main-content">
            <h4>Strategy & Planning</h4>
            <p>
            We analyze your business needs and target audience to develop a comprehensive strategy and roadmap, ensuring your website achieves your goals effectively.

            </p>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="service-item">
          <div className="icon">
            <img src={crossSvg} alt="short courses" />
          </div>
          <div className="main-content">
            <h4>Design & Development</h4>
            <p>
            Our team creates visually stunning, responsive websites using the latest technologies, ensuring a seamless user experience across all devices.
            </p>
            
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="service-item">
          <div className="icon">
            <img src={product2} alt="web experts" />
          </div>
          <div className="main-content">
            <h4>Launch & Maintenance</h4>
            <p>
            From launch to ongoing maintenance, we ensure your website remains updated, secure, and optimized, providing continuous support and enhancements as your business grows.

            </p>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


</>

   
  )
}

export default WelcomeSection