import React from 'react';
import style from "./SectionOne.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import choose from "../../images/why-choose-us-img.jpg";
import aboutSec from "../../images/about-sec.jpg"
const SectionOne = () => {
  return (
    <>
      {/* Start Why Choose Us Section */}
      <div className="why-choose-section">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-6">
              <p className={style.text1}>Empowering Your Digital Journey</p>
              <h2 className="section-title">Unlocking Digital Potential</h2>
              <p>
              Embark on a transformative digital journey with [Your Company Name]. With a wealth of experience and a passion for excellence, we specialize in crafting exceptional websites and implementing effective SEO strategies. Trust us to elevate your online presence and drive tangible results.
              </p>
              <ul className={`row my-5 ${style.noListStyle}`}>
                <li className={`col-12 ${style.listItem}`}>
                  <FontAwesomeIcon icon={faCheckCircle} className={style.icon} />
                 <b> Expertise:</b> With years of experience in the industry, we bring a depth of knowledge and expertise to every project.
                </li>
                <li className={`col-12 ${style.listItem}`}>
                  <FontAwesomeIcon icon={faCheckCircle} className={style.icon} />
                  <b>Creative Excellence:</b> Our talented team of professionals is dedicated to delivering high-quality solutions that exceed expectations.
                </li>
                <li className={`col-12 ${style.listItem}`}>
                  <FontAwesomeIcon icon={faCheckCircle} className={style.icon} />
                  <b>Client Satisfaction:</b> Our success is measured by the success of our clients. We prioritize your satisfaction and strive to exceed your expectations.
                </li>
              </ul>
            </div>
            <div className="col-lg-5">
          <div className="img-wrap">
            <img
              src={aboutSec}
              alt="Image"
              className="img-fluid"
            />
          </div>
        </div>



          </div>

        </div>
      </div>
      {/* End Why Choose Us Section */}
    </>
  );
};

export default SectionOne;
