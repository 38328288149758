import {useEffect} from 'react'
import CounterStats from '../Components/About/CounterStats'
import SectionOne from '../Components/About/SectionOne'

import SectionThree from '../Components/About/SectionThree'
import Breadcrumb from "react-bootstrap/Breadcrumb";

const About = () => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      window.scrollTo(0, 0);
    }, ); // Adjust the delay as needed

    return () => clearTimeout(timeout); // Clear timeout on component unmount
  }, []); 
  return (
  <>

   <div className="banner-layout-about">
        <div className="container">
          {/* <img className='img-fluid' src={bannerlayout} /> */}
          <div className="text-center">
            <h1 className="text-white">About Us</h1>
            <Breadcrumb>
              <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>About Us</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>


  <SectionOne/>



  <CounterStats/>

  <SectionThree/>
  
  </>
  )
}

export default About
