import React, { useState } from "react";
import axios from "axios";
import Breadcrumb from "react-bootstrap/Breadcrumb";

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    clientEmail: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const validate = () => {
    let validationErrors = {};

    if (!formData.firstName) {
      validationErrors.firstName = "First name is required";
    }

    if (!formData.lastName) {
      validationErrors.lastName = "Last name is required";
    }

    if (!formData.phoneNumber) {
      validationErrors.phoneNumber = "Phone number is required";
    } else if (!/^\d{10}$/.test(formData.phoneNumber)) {
      validationErrors.phoneNumber = "Phone number must be 10 digits";
    }

    if (!formData.clientEmail) {
      validationErrors.clientEmail = "Email address is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.clientEmail)) {
      validationErrors.clientEmail = "Invalid email address";
    }

    if (!formData.message) {
      validationErrors.message = "Message is required";
    }

    return validationErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      await axios.post("https://zgomo-form-backend.vercel.app/form", formData);
      alert("Your record has been submitted successfully!");
      setFormData({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        clientEmail: "",
        message: "",
      });
      setErrors({});
    } catch (err) {
      setErrors({ submit: "Error submitting form. Please try again." });
      alert("Error submitting form. Please try again.");
    }
  };
  return (
    <>

<div className="banner-layout-contact">
            <div className="container">
              {/* <img className='img-fluid' src={bannerlayout} /> */}
              <div className="text-center">
                <h1 className="text-white">Contact Us</h1>
                <Breadcrumb>
                  <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                  <Breadcrumb.Item active>
                  Contact Us
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
          </div>



        <div className="untree_co-section section-entry">
  <div className="container">
    <div className="block">
      <div className="row justify-content-center">
        
        <div className="col-md-12">
          <div className="row mb-5">
            <div className="col-lg-4">
              <div
                className="service no-shadow align-items-center link horizontal d-flex active"
                data-aos="fade-left"
                data-aos-delay={0}
              >
                <div className="service-icon color-1 mb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className="bi bi-geo-alt-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                  </svg>
                </div>{" "}
                {/* /.icon */}
                <div className="service-contents">
                  <p>B-64,Noida, UP-201301, India</p>
                </div>{" "}
                {/* /.service-contents*/}
              </div>{" "}
              {/* /.service */}
            </div>
            <div className="col-lg-4">
              <div
                className="service no-shadow align-items-center link horizontal d-flex active"
                data-aos="fade-left"
                data-aos-delay={0}
              >
                <div className="service-icon color-1 mb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className="bi bi-envelope-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z" />
                  </svg>
                </div>{" "}
                {/* /.icon */}
                <div className="service-contents">
                  <p>services@zgomo.com</p>
                </div>{" "}
                {/* /.service-contents*/}
              </div>{" "}
              {/* /.service */}
            </div>
            <div className="col-lg-4">
              <div
                className="service no-shadow align-items-center link horizontal d-flex active"
                data-aos="fade-left"
                data-aos-delay={0}
              >
                <div className="service-icon color-1 mb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className="bi bi-telephone-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                    />
                  </svg>
                </div>{" "}
                {/* /.icon */}
                <div className="service-contents">
                  <p>+919354414923</p>
                </div>{" "}
                {/* /.service-contents*/}
              </div>{" "}
              {/* /.service */}
            </div>
          </div>

          <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <label className="text-black" htmlFor="firstName">
              First name
            </label>
            <input
              type="text"
              className="form-control"
              id="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
            {errors.firstName && (
              <div className="error-message">{errors.firstName}</div>
            )}
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label className="text-black" htmlFor="lastName">
              Last name
            </label>
            <input
              type="text"
              className="form-control"
              id="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
            {errors.lastName && (
              <div className="error-message">{errors.lastName}</div>
            )}
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label className="text-black" htmlFor="phoneNumber">
              Phone Number
            </label>
            <input
              type="text"
              className="form-control"
              id="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
            />
            {errors.phoneNumber && (
              <div className="error-message">{errors.phoneNumber}</div>
            )}
          </div>
        </div>
        <div className="form-group col-6">
          <label className="text-black" htmlFor="clientEmail">
            Email address
          </label>
          <input
            type="email"
            className="form-control"
            id="clientEmail"
            value={formData.clientEmail}
            onChange={handleChange}
            required
          />
          {errors.clientEmail && (
            <div className="error-message">{errors.clientEmail}</div>
          )}
        </div>
        <div className="form-group col-12 mb-5">
          <label className="text-black" htmlFor="message">
            Message
          </label>
          <textarea
            className="form-control"
            id="message"
            cols={30}
            rows={3}
            value={formData.message}
            onChange={handleChange}
            required
          />
          {errors.message && (
            <div className="error-message">{errors.message}</div>
          )}
        </div>
        <div className="text-center">
          <button type="submit" className="my-btn">
            Send Message
          </button>
        </div>
        {errors.submit && <div className="error-message">{errors.submit}</div>}
      </div>
    </form>

        </div>
      </div>
    </div>
  </div>
</div>



<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d112040.89263501647!2d77.13582137687581!3d28.65139676769346!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce54e27720327%3A0xf74e16131cb070e!2sB-64%2C%20Sector%2026%2C%20Noida!5e0!3m2!1sen!2sin!4v1718486420330!5m2!1sen!2sin" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>


    </>
  )
}

export default Contact
