import {useEffect} from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Accordion from 'react-bootstrap/Accordion';

import seo from "../images/service2.jpg";
import SectionThree from "../Components/About/SectionThree";

const Seo = () => {
    useEffect(() => {
        const timeout = setTimeout(() => {
          window.scrollTo(0, 0);
        }, ); // Adjust the delay as needed
    
        return () => clearTimeout(timeout); // Clear timeout on component unmount
      }, []); 
    
      return (
        <>
          <div className="banner-layout">
            <div className="container">
              {/* <img className='img-fluid' src={bannerlayout} /> */}
              <div className="text-center">
                <h1 className="text-white">Search Engine Optimization</h1>
                <Breadcrumb>
                  <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href="#">Sevices</Breadcrumb.Item>
                  <Breadcrumb.Item active>
                  Search Engine Optimization
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
          </div>
    
          <div className="ser-lay section-entry ">
            <div className="container">
              <div className="row align-c">
                <div className="col-md-6">
                  <div>
                    <img className="img-fluid" src={seo} />
                  </div>
                </div>
                <div className="col-lg-6">
                  <h2 className="section-title mb-4">
                    SEO{" "}
                    <span className="span-clr">(Search Engine Optimization)</span>
                  </h2>
                  <p>
                  In the competitive digital landscape, standing out in search engine results is crucial for attracting organic traffic and growing your business. At Zgomo, we specialize in comprehensive Search Engine Optimization (SEO) services designed to enhance your website's visibility and drive targeted traffic.
                  </p>
                  <p>
                  Our SEO strategy begins with a thorough analysis of your website and industry to identify opportunities for improvement. We conduct extensive keyword research to ensure your content aligns with what your audience is searching for. Our on-page optimization techniques, including meta tags, alt texts, and content enhancements, ensure your site is search engine friendly.
                    </p>
                </div>
                <p>
                We also focus on off-page SEO, building high-quality backlinks and leveraging social media signals to boost your site's authority. Regular performance tracking and reporting keep you informed about your SEO progress and help us refine our strategies for continuous improvement.
                </p>
                <p>By staying up-to-date with the latest SEO trends and algorithm changes, we ensure your website maintains high rankings and drives sustainable growth. Partner with us to elevate your online presence and achieve your business objectives through effective SEO.

</p>
              </div>
            </div>
          </div>
    
    
          {/* <div className="ser-wel section-entry">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 ser-col">
                    <h2 className="section-title text-center mb-4">
                    Our{" "}
                    <span className="span-clr">Process</span>
                  </h2>
                    </div>
                    <div className="col-md-4 ser-cl-col">
                        <div className="ser-wrap-inside">
                            <h4>Step 1</h4>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure aliquam velit sit animi eligendi, commodi nostrum? Ipsum, ex totam! Cum iure praesentium accusamus accusantium dolores molestias est quo quasi inventore?</p>
                        </div>
                    </div>
                    <div className="col-md-4 ser-cl-col">
                        <div className="ser-wrap-inside">
                            <h4>Step 2</h4>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure aliquam velit sit animi eligendi, commodi nostrum? Ipsum, ex totam! Cum iure praesentium accusamus accusantium dolores molestias est quo quasi inventore?</p>
                        </div>
                    </div>
                    <div className="col-md-4 ser-cl-col">
                        <div className="ser-wrap-inside">
                            <h4>Step 3</h4>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure aliquam velit sit animi eligendi, commodi nostrum? Ipsum, ex totam! Cum iure praesentium accusamus accusantium dolores molestias est quo quasi inventore?</p>
                        </div>
                    </div>
                </div>
            </div>
          </div> */}
    
    
          {/* <div className="ser-fqa section-entry">
            <div className="container">
                <div className="row ser-fqa align-c">
                    <div className="col-md-6">
                        <div className="ser-fqa-l">
                        <h2 className="section-title">
                    Our <span className="span-clr">FQAS</span> </h2>
                            <h4>Have Questions? We Have Answers!</h4>
                            <p>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum quis aliquid voluptates quasi reprehenderit natus distinctio possimus. Voluptatem similique aperiam commodi numquam sapiente distinctio est!
                            </p>
                            <p>
                                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quasi alias deleniti inventore tenetur quis voluptatibus maiores. Unde quia iusto voluptates odio dignissimos sint mollitia, maiores quam reprehenderit numquam quos amet?
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6 ser-fqa-col">
                        <div className="ser-fqa-wrap">
                        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>What is SMO and why do I need it for my website?</Accordion.Header>
            <Accordion.Body>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
              minim veniam, quis nostrud exercitation.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>How long does it take to see results from SEO?</Accordion.Header>
            <Accordion.Body>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
              minim veniam, quis nostrud exercitation ullamco.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>What kind of SEO strategies does Quixta offer?</Accordion.Header>
            <Accordion.Body>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
              minim veniam, quis nostrud exercitation ullamco.
            </Accordion.Body>
          </Accordion.Item>
          
        </Accordion>
                        </div>
                    </div>
                </div>
            </div>
          </div> */}
    
        <SectionThree />
    
        </>
      );
}

export default Seo;
