import {useEffect} from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Accordion from 'react-bootstrap/Accordion';

import smo from "../images/service3.jpg";
import SectionThree from "../Components/About/SectionThree";

const Smo = () => {
      
  useEffect(() => {
    const timeout = setTimeout(() => {
      window.scrollTo(0, 0);
    }, ); // Adjust the delay as needed

    return () => clearTimeout(timeout); // Clear timeout on component unmount
  }, []); 

  return (
    <>
      <div className="banner-layout">
        <div className="container">
          {/* <img className='img-fluid' src={bannerlayout} /> */}
          <div className="text-center">
            <h1 className="text-white">Social Media Optimisation</h1>
            <Breadcrumb>
              <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
              <Breadcrumb.Item href="#">Sevices</Breadcrumb.Item>
              <Breadcrumb.Item active>
                Social Media Optimisation
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>

      <div className="ser-lay section-entry ">
        <div className="container">
          <div className="row align-c">
            <div className="col-md-6">
              <div>
                <img className="img-fluid" src={smo} />
              </div>
            </div>
            <div className="col-lg-6">
              <h2 className="section-title mb-4">
                SMO{" "}
                <span className="span-clr">(Social Media Optimization)</span>
              </h2>
              <p>
              In today's digital age, having a strong social media presence is essential for any business aiming to connect with its audience and build brand awareness. Social Media Optimization (SMO) involves strategically using social media platforms to enhance your online presence, drive traffic, and engage with your audience.


              </p>
              <p>
              At <b>Zgomo</b>, we specialize in crafting effective SMO strategies tailored to your business needs. Our approach includes optimizing your social media profiles, creating engaging content, and leveraging analytics to measure performance and refine tactics. By focusing on high-quality visuals, compelling copy, and timely interactions, we ensure your brand stands out in the crowded social media landscape
              </p>
            </div>
            <p>
            Whether you're looking to increase followers, boost engagement, or drive conversions, our SMO services are designed to help you achieve your goals. Let us help you harness the power of social media to grow your business and connect with your audience like never before.
            </p>
          </div>
        </div>
      </div>


      {/* <div className="ser-wel section-entry">
        <div className="container">
            <div className="row">
                <div className="col-md-12 ser-col">
                <h2 className="section-title text-center mb-4">
                Our{" "}
                <span className="span-clr">Process</span>
              </h2>
                </div>
                <div className="col-md-4 ser-cl-col">
                    <div className="ser-wrap-inside">
                        <h4>Step 1</h4>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure aliquam velit sit animi eligendi, commodi nostrum? Ipsum, ex totam! Cum iure praesentium accusamus accusantium dolores molestias est quo quasi inventore?</p>
                    </div>
                </div>
                <div className="col-md-4 ser-cl-col">
                    <div className="ser-wrap-inside">
                        <h4>Step 2</h4>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure aliquam velit sit animi eligendi, commodi nostrum? Ipsum, ex totam! Cum iure praesentium accusamus accusantium dolores molestias est quo quasi inventore?</p>
                    </div>
                </div>
                <div className="col-md-4 ser-cl-col">
                    <div className="ser-wrap-inside">
                        <h4>Step 3</h4>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure aliquam velit sit animi eligendi, commodi nostrum? Ipsum, ex totam! Cum iure praesentium accusamus accusantium dolores molestias est quo quasi inventore?</p>
                    </div>
                </div>
            </div>
        </div>
      </div> */}

{/* 
      <div className="ser-fqa section-entry">
        <div className="container">
            <div className="row ser-fqa align-c">
                <div className="col-md-6">
                    <div className="ser-fqa-l">
                    <h2 className="section-title">
                Our <span className="span-clr">FQAS</span> </h2>
                        <h4>Have Questions? We Have Answers!</h4>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum quis aliquid voluptates quasi reprehenderit natus distinctio possimus. Voluptatem similique aperiam commodi numquam sapiente distinctio est!
                        </p>
                        <p>
                            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quasi alias deleniti inventore tenetur quis voluptatibus maiores. Unde quia iusto voluptates odio dignissimos sint mollitia, maiores quam reprehenderit numquam quos amet?
                        </p>
                    </div>
                </div>
                <div className="col-md-6 ser-fqa-col">
                    <div className="ser-fqa-wrap">
                    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>What is SEO and why do I need it for my website?</Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>How long does it take to see results from SEO?</Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>What kind of SEO strategies does Quixta offer?</Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco.
        </Accordion.Body>
      </Accordion.Item>
      
    </Accordion>
                    </div>
                </div>
            </div>
        </div>
      </div> */}

    <SectionThree />

    </>
  );
};

export default Smo;
