
// import person from "../../images/product-1.png";



// import React from 'react';
// import { Carousel } from 'react-bootstrap';

import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "../../bootstrapCss/style.css";

const testimonials = [
  {
    text: "Working with Zgomo was a game-changer for our business. Their team created a stunning and highly functional website that perfectly represents our brand. The process was smooth, and they were responsive to all our needs. We’ve seen a significant increase in our online engagement since the launch.",
    name: "Rohit Sharma",
    // title: "CEO, Company"
  },
  {
    text: "Zgomo exceeded our expectations in every way. They took the time to understand our vision and brought it to life with a beautiful, user-friendly website. Their expertise in design and development is unmatched, and the ongoing support has been invaluable. Our customers love the new site!"
    ,
    name: "Shivam Yadav",
    // title: "CTO, Company"
  },
  {
    text: "I couldn’t be happier with the website Zgomo developed for us. Their attention to detail and commitment to quality are evident in every aspect of the site. They made the entire process effortless and delivered a product that has significantly boosted our online presence. Highly recommend their services!",
    name: "Deepak Thakur",
    // title: "COO, Company"
  },
  
];

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1
      }
    }
  ]
};

const Testimonial = () => {
  return (
    <div className="testimonial-slider-hm section-entry">
      <div className='container'>
      <h2 className="section-title text-center">Our <span className='span-clr'>Testimonials</span></h2>

        <div className='row'>
          <div className='col-md--12'>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-item">
            <p>"{testimonial.text}"</p>
            <h5>- {testimonial.name}</h5>
            <p>{testimonial.title}</p>
          </div>
        ))}
      </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
