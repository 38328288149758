import React from 'react'
import Truck from "../../images/truck.svg";
import Bag from "../../images/bag.svg";
import support from "../../images/support.svg";
import returnImg from "../../images/return.svg";
import choose from "../../images/why-choose.jpg";
import "../../bootstrapCss/style.css";
const WelcomeSection2 = () => {
  return (
 <>
  {/* Start Why Choose Us Section */}
  <div className="why-choose-section">
    <div className="container">
      <div className="row justify-content-between">
      <div className="col-lg-7">
          <h2 className="section-title">Why <span className='span-clr'>Choose Us</span></h2>
          <p>
          Choosing the right partner for your website development needs can make all the difference in achieving your business goals. Here’s why Zgomo stands out:


          </p>
          <div className="row my-5">
            <div className="col-6 col-md-6">
              <div className="feature">
                <div className="icon">
                  <img
                    src={Truck}
                    alt="Image"
                    className="imf-fluid"
                  />
                </div>
                <h3>Expertise and Experience:</h3>
                <p>
                With years of experience in the industry, our team of skilled developers and designers brings a wealth of knowledge and expertise to every project. We stay updated with the latest trends and technologies to deliver cutting-edge solutions.
                </p>
              </div>
            </div>
            <div className="col-6 col-md-6">
              <div className="feature">
                <div className="icon">
                  <img src={Bag} alt="Image" className="imf-fluid" />
                </div>
                <h3>Customized Solutions</h3>
                <p>
                We understand that every business is unique. That’s why we offer personalized web development services tailored to meet your specific needs and objectives. From design to functionality, we ensure your website reflects your brand’s identity.
                </p>
              </div>
            </div>
            <div className="col-6 col-md-6">
              <div className="feature">
                <div className="icon">
                  <img
                    src={support}
                    alt="Image"
                    className="imf-fluid"
                  />
                </div>
                <h3>SEO Optimization:</h3>
                <p>
                A great website is nothing without visibility. Our SEO specialists optimize your site to rank higher in search engine results, driving more organic traffic and increasing your online presence.


                </p>
              </div>
            </div>
            <div className="col-6 col-md-6">
              <div className="feature">
                <div className="icon">
                  <img
                    src={returnImg}
                    alt="Image"
                    className="imf-fluid"
                  />
                </div>
                <h3>Ongoing Support</h3>
                <p>
                  Our commitment doesn’t end at launch. We provide ongoing maintenance and support to keep your website running smoothly and securely. Whether it’s updates, troubleshooting, or enhancements, 
                </p>
              </div>
            </div>
          </div>
        </div>
      <div className="col-lg-5">
          <div className="img-wrap">
            <img
              src={choose}
              alt="Image"
              className="img-fluid"
            />
          </div>
        </div>
        
        
      </div>
    </div>
  </div>
  {/* End Why Choose Us Section */}
</>

  )
}

export default WelcomeSection2