import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import styles from './SectionThree.module.css'; // Import CSS Module

function SectionThree() {
  return (
    <Card className={`text-center ${styles.card}`}>
      <Card.Body className="d-flex flex-column align-items-center">
        <Card.Title className={`${styles.title} ${styles.animateTitle}`}>
          Let’s Make Your Vision A Reality
        </Card.Title>
        <Card.Text className={styles.text}>
          Have a project in mind or just want to say hello? We’d love to hear from you! Contact
        </Card.Text>
        <Card.Text className={styles.text}>
          us today and let’s start your journey towards a dynamic and customer-centric website.
        </Card.Text>
        <Button className={styles.button}>Contact Us</Button>
      </Card.Body>
    </Card>
  );
}

export default SectionThree;
